.links {
    text-align: center;
}

.signIn{

    &__form{
        width: 80%;
        margin: 0 auto;
        text-align: center;
    }

    &__button{
        color: white;
        font-size:1.2rem;
        font-weight: bold;
        border: none;
        padding: 1rem 2rem;
        border-radius: 1.6rem;
        background-color: $color-secondary-handy;
     }
 
     &__link {
         font-family: 'Georgia'
 
     }
 
     &__heading {
         font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
         font-size: 2.2rem;
     }

     &__input{
    font-size: 1.5rem;
    font-family: inherit;
    padding: 0.2rem 1rem;
    border-radius: 3rem;
    background-color: $color-white;
    border: none;
    border-bottom: 3px solid transparent;
    display: block;
    background-color: $color-white;
    width: 100%;
    margin-top: 2rem;
     }
}

@media (min-width:40rem){
    .signIn{
        &__form{
            width: 35rem;
        }
        &__button{
            background-color: $color-secondary;
        }
    }
}