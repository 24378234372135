.forgot-password__form{
    width: 80%;
    margin: 0 auto;
    text-align: center;

    &-input{

    font-size: 1.5rem;
    font-family: inherit;
    padding: 0.2rem 1rem;
    border-radius: 3rem;
    background-color: $color-white;
    border: none;
    border-bottom: 3px solid transparent;
    display: block;
    background-color: $color-white;
    width: 100%;
    margin-top: 2rem;
    }
}

@media (min-width:40rem){
    .forgot-password__form{
        width: 35rem;
    }
}