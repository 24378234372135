.termsofusepage{

padding: 1rem;

  h1{
    color: $color-white
  }
    h2{
        color: $color-white;
    }
    h3{
        color: $color-white;
    }
    h4{
        color: $color-white;
    }
    h5{
        color: $color-white
    }
    p{
        color: $color-white;
    }
    ul {
      
       li{
        color: $color-white;

        ul{
            li{
                color: $color-white;
            }
        }
       }

    }
     
}