
//Colors
$color-primary:  #55c57a;
$color-primary-light: #7ed56f;
$color-primary-dark: #28b485;

$color-secondary-light: #ffb900;
$color-secondary-dark: #ff7730;

$color-tertiary-light: #2998ff;
$color-tertiary-dark: #5643fa;

$color-grey-light-1: #f7f7f7;
$color-grey-light-2: #eee;


$color-grey-dark: #777;
$color-grey-dark-2: #999;
$color-grey-dark-3: #333;

$color-white:#fff;
$color-black:#000;
//font
$default-font-size: 1.6rem;

//GRID
$grid-width: 114rem;
$gutter-vertical: 8rem;
$gutter-horizontal:6rem;

$border-radius-small: 3px;


$color-primary: #5c2ce0;
$color-secondary: #FF945F;
$color-tertiary: #FEE9FF;
$color-primary-image:  black;

$color-primary-handy: #000;
$color-secondary-handy: #A3CFCD;
$color-tertiary-handy:#82A0AA;