.menu{
    height: 100vh;
    display: flex;
    flex-direction: column;
     justify-content: center;
    align-items: center;
  
   
    &__linkLegal{
       color: $color-white;
       text-decoration: none;
       font-weight: bold;
       font-size:x-large;
      
    }
    &__linkPrivacy{
        color: $color-white;
        text-decoration: none;
        font-weight: bold;

        font-size:x-large;
    }
    &__linkTermofuse{
        color: $color-white;
        text-decoration: none;
        font-weight: bold;
        font-size: x-large;
       
    }

    &__link-credit{
        color: $color-white;
        text-decoration: none;
        font-weight: bold;
        font-size: x-large;

    }
}
.menu > *{
    transition: all .2s;
    margin-bottom: 1.5rem;
}

@media (min-width: 40rem){
.menu > *:hover{
   background-color: black;
   border: solid 1px white;
   padding: 1rem 2rem;
   border-radius: 20px;
   transform: scale(1.1);
}
}