*{
   // background-color: $color-black;
   box-sizing: border-box;
} 


body {
    margin: 0 0 55px 0;
    background-color: $color-primary-handy;
   
}

@media(min-width: 40rem){
    html{
        background-image: $color-primary-image;
    }

    body{
       //  background-color: $color-primary;
       background-image: $color-primary-image;
       background-repeat: no-repeat;

    }
}

