.cookie-banner__full{
  position: fixed;
  top: 1rem;
  background-color: gray;
  left: 50%;
  transform: translate(-50%,0);
  width: 80%;
  padding: 1rem;
}


.CookieBanner {
 
    height: auto;
    padding: 0.5rem 1rem;
    overflow: scroll;

    &__buttons{
       display: flex;
       justify-content: center;
      
    }


    &__buttonaccept{
      border: none;
      padding: 1rem 2rem;
      border-radius: 1.6rem;
      background-color: $color-primary-light;
      color: $color-black;
      margin-right: 0.5rem;
    }

    &__buttondecline{
      border: none;
      padding: 1rem 2rem;
      border-radius: 1.6rem;
      background-color: rgb(193, 189, 189);
      color: $color-black;

    }

    &__showmorebutton{
      margin-top: 1rem;
      display: flex;
      align-items: center;
      border: none;
      background-color:rgb(110, 110, 255);
      padding: 0.5rem 1rem;
      border-radius: 1.6rem;
     float: right;
     margin-right: 1rem; 
     color: black;
      
    }

    &__icon{
      margin-left: 1rem;
    }

    &__privacyPolicy{
      height: 40vh;
      overflow: scroll;
      padding-bottom: 2rem;
    }
}

@media(min-width: 40rem){
  .cookie-banner__full{
    width: 35rem;
  }
}

