.marginbottom-small{

    margin-bottom: 2rem;
}

.margintop-small{

    margin-top: 2rem;
}


.margintop-medium{

    margin-top: 4rem;
}

.marginbottom-tiny{

    margin-bottom: 1rem;
}

.marginbottom-medium{

    margin-bottom: 4rem;
}