.autocomplite-all{
    

    &__search-bar{
        font-size: 1.5rem;
        font-family: inherit;
        padding: 0.2rem 1rem;
        border-radius: 3rem;
        background-color: $color-white;
        border: none;
        border-bottom: 3px solid transparent;
        display: block;
        margin: 0 auto;
        margin-top: 2rem;
        width: 90%;

    }


}

.autocomplite{
    display: flex;
    justify-content: center;
    background-color: black;
 
     &__list {
   padding: 0;
   width: 90%;

   li{
    background-color: $color-secondary-handy;
   }
 
     }
 
 }

@media (min-width: 40rem){
  .autocomplite-all{
    
    &__search-bar{
        width: 25rem;
        display: block;
    }

  }

  .autocomplite{
     background-color: inherit;


    &__list{
        width: 25rem;
       

        li{
          background-color: $color-secondary;
        }
    }
  }
   
}