.popUp{
    position: fixed;
    top: 50%;
    left: 50%;
    max-height: 60%;
    max-width: 60%;
    transform: translate(-50%,-50%);
    border: solid 4px rgb(202, 202, 202);
    background-size: 100%;
    z-index: 1000;
   background-position: center;
    border-radius: 10px;

    &__button {
        position: fixed;
       color: rgb(176, 176, 176);
        top: 3%;
        right: 5%;
        z-index: 750;
    }

    &__background {
   position: fixed;
   top: 0;
   left: 0;
   height: 100vh;
   width: 100%;
   background-color: rgba(rgb(108, 107, 107), 0.4);
   z-index: 500;
    }
}