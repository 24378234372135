.search {
    width: 100%;
    display: flex;
    justify-content: center;

    &__Result {
    background-color: rgba($color-grey-dark,1);
    color: $color-white;
    padding: 20px;
    list-style: none;
    

    }

    &__header {
        width: 80%;
        margin: 0 auto;
    }
}




