.MyInvitation {
    border-bottom: 1px solid $color-white;

    &__textheading {
        color: white;
        font-size: 2.2rem;
        margin-bottom: 0;
    }



    &__top{
width: 80%;

margin: 0 auto;
position: relative;
height: 10rem;
    }
    &__img {
         
        width: 100%;
    }

&__image {
    position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: -1rem;
        width: 4.5rem;
        height: 4.5rem;
       background-size: cover;
        border-radius: 10px;

}
&__bottom {
    position: relative;
}
&__image2{
    position: absolute;
    bottom: 1rem;
    right: 1rem;
    width: 4.5rem;
    height: 4.5rem;
    background-size: cover;
}

&__button {
    border: none;
    color: white;
    background-color: transparent;
    position: absolute;
    top: 35%;
    right: 2%;

  

}

&__texte{
    position: absolute;
    top: 50%;
    left: 4.5rem;
    transform: translate(0,-50%);
    width: calc(80% - 4rem - 3%);
    max-width: 15rem;
   overflow: scroll;
}

&__text {
color: rgb(151, 151, 151);
margin: 0;

&-up {
    color: rgb(151, 151, 151);
    margin: 0;
    margin-bottom: 1rem;
    
}
}



&__button-expand {
    border: none;
    color: white;
    background-color: transparent;
    position: absolute;
    bottom: 1rem;
    margin: 0 auto;

}
&__bottom {
    width: 80%;
    margin: 0 auto;
    margin-bottom: 2rem;
}

}