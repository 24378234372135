.navbar {
position: fixed;
bottom: 0rem;
width: 100%;
height:4.5rem;
box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
display: flex;
overflow-x: auto;
opacity: 1;
background-color: inherit;
margin-top: 20px;
transition: all .2s;


&__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    min-width: 50px;
    overflow: hidden;
    white-space: nowrap;

   
}

&__icon {
    color: $color-white;
    transition: all .2s;
}



}

@media (min-width:40rem){
    .navbar{
        background-color: $color-secondary;
    }
   .navbar__icon:hover{
      transform: translateY(-0.5rem) scale(1.4);
   }
   .navbar__icon:active{
    transform: translateY(0.2rem) scale(1);
   }
}