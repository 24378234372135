.credit-page{
   padding: 2rem;

    &__heading{
     color: $color-white;

    }
    &__text{
        color: $color-white;
    }

    &__link{
       color: $color-white;
    }
}