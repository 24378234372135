.Invitation {
    border-bottom: $color-grey-dark solid 1px;
    width: 80%;
    margin: 0 auto;
    transition: all .3s;
    padding: 1rem;

    &__top {
  
    margin-bottom: 2rem;
    position: relative;


    }
    &__bottom{
        position: relative;
    }

    &__image {
        position: absolute;
        top: 10%;
        left: 0px;
        width: 4.5rem;
        height: 4.5rem;
       background-size: cover;
        border-radius: 10px;
    }

    &__image2{
        position: absolute;
        right: 1rem;
         bottom: 330px;
         transform: translateY(-50%);
         width: 7rem;
         height: 7rem;
        background-size: cover;
    }
    &__karte{
        position: absolute;
    }

   &__heading{
    color: white;
    font-family: 'Open Sans', sans-serif;
    font-size: 1.5rem;
    overflow-wrap: break-word;
   }
   
       &__text {
           color: white;
           font-family: 'Open Sans', sans-serif;
           font-size: 1rem;
           overflow-wrap: break-word;
       }
     
       &__age {
        color: white;
        position: absolute;
        top: 5%;
        right: 7%;
        margin: 0;
        font-size: 1.7rem;

       }

       &__img {
         
           width: 100%;
           max-height: 11rem;
       }
       &__button {
           border: none;
           background-color: transparent;
           position: absolute;
           top: 40%;
           right: 2%;
   
       }
       &__expandIcon {
         margin-left: 2rem;
       }
    
   
       &__texte {
      margin-left: 5.5rem;
      width: calc(80% - 7% - 4.5rem);
      overflow: scroll;
       }
   
       &__figure {
         max-width: 30%;
         margin-right: 2rem;
       }

       &__list {
          list-style: none;
          padding: 0;
          margin: 0;
          margin-bottom: 5rem;
       }

     
   
   }

   @media(min-width: 40rem){

    .Invitation{
       width: 23rem;
      display: inline-block;
      margin: 1rem;
     vertical-align: top;
    }
    .Invitation:last-child{
        margin-right: 0rem;
    }
    .Invitation:hover{
      
        border-radius: 20px;
        border: white solid 1px;
        padding: 1rem;
      
    }
    .Invitation__list{
        width: 100vh;
        display: inline;
       
        margin: 0 auto;
    }
   }