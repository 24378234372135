
.terms-of-use__container{
  
   position: fixed;
   background-color: white;
   top: 10%;
   left: 50%;
   width: 80%;
   transform: translate(-50%,0);
   height: 50vh;
   
}
.terms-of-use__scroll-button{
   background-color: rgb(5, 83, 240);
   height: 3rem;
   display: flex;
   justify-content: center;
   align-items: center;
}

.termsofuse{
  width: 100%;
   height: 100%;
   overflow: scroll;
background-color: white;
padding: 0 1rem;



&__background{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
   background-color: rgba($color-black, 0.5);

}

&__button{
   border: none;
   padding: 0.5rem 1rem;
   border-radius: 1.6rem;
   background-color: rgb(110, 110, 255);
   color: $color-white;
   margin-bottom: 2rem;
}
}

@media (min-width: 40rem){
   .terms-of-use__container{
      width: 35rem;
   }
}