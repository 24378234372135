.globalInvitations{
  


    &__headingdiv{
     text-align: center;
    }

    &__headingtext{
        color: white;
        font-family: 'Arial', 'Helvetica', sans-serif;
        font-size: 1.5rem;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        z-index: -1;
    }

  &__rangediv{
    width: 80%;
    margin: 0 auto;
    border: solid 2px $color-tertiary-handy;
    padding: 1rem;
    background-color: $color-secondary-handy;
  
  }
  &__range{
   display: block;
   width: 80%;
   accent-color: $color-tertiary-handy; 
   position: relative;
   left: 10%;
  }

  &__button{
    font-family: 'Arial', sans-serif;
    color: white;
    font-size: 1rem;
    font-weight: bold;
    display: block;
    margin: 0 auto;
    margin-top: 1rem;
    border: 1px solid $color-tertiary-handy;
    padding: 1rem 2rem;
    border-radius: 1.6rem;
    background-color: $color-secondary-handy;

  }

  &__heading {
    font-family: 'Luckiest Guy';
    font-size: 2.5rem;
    font-weight: 900;

  }

  &__text {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
   color: white;
   font-size: 1.2rem;
  }

}

@media (min-width: 40rem){

  .globalInvitations{

    &__body{
     
    }

    &__rangediv{
      width: 30rem;
      margin: 0 auto;
       background-color: $color-secondary;
       border-radius: 20px;
       border: solid 1px $color-tertiary;
    }

    &__range{
      accent-color: $color-tertiary; 
    }
    &__button{
      background-color: $color-secondary;
      border: 1px solid $color-tertiary;
    }

  }
  
  
}