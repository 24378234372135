.createInvitations{
    margin-left: 0rem;
    padding: 1.5rem;

    &__text{
        color:white;
        margin-top: 2rem;
        font-weight: bold;
    }

    &__input{
        font-size: 1.5rem;
        font-family: inherit;
        padding: 0.3rem 1rem;
        border-radius: 3rem;
        background-color: $color-white;
        border: none;
        border-bottom: 3px solid transparent;
        display: block;
         width: 100%;
        color: $color-black;
  

        &__number {
            font-size: 1.5rem;
            font-family: inherit;
            padding: 0.2rem 1rem;
            border-radius: 3rem;
            background-color: $color-white;
            border: none;
            border-bottom: 3px solid transparent;
            width: 100%;
            display: block;
        

        }
     &__datei {
        font-size: 11px;
        background:white;
        border-radius: 50px;
        width: 90%;
        outline: none;
        padding: 1rem;
        color: $color-black;
        
     }
    



      
    }

    &__submit{
        margin-top: 3rem;
        display: flex;
        justify-content: center;
        
       }
&__form {
    margin-bottom: 6rem;
}

}

@media (min-width: 40rem){
     .createInvitations{
        margin: auto;
        width: 25rem;
     }
}