.text {
    color: white;

}



.profil{
     position: relative;
   
 
    &__input {
        font-size: 1.5rem;
        font-family: inherit;
        padding: 0.2rem 1rem;
        border-radius: 3rem;
        background-color: $color-white;
        border: none;
        border-bottom: 3px solid transparent;
       width: 75%;
       
        margin-right: 10px;
    }

    &__icon {
       position: absolute;
       top: 8px;
    }

    &__button{
      margin-bottom: 2rem;
      color: white;
      font-size: 1.2rem;
      font-weight: 900;
    }

    &__form{
             margin-bottom: 2rem;

    }
    
  &__heading {
        font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
         font-size: 2.2rem;
      }
   &__text {

    font-family: Georgia, 'Times New Roman', Times, serif;
    font-size: 1.2rem;

   &__NOINV {
    color: white;
    


  
   }

  }
  &__link {
    font-family: 'Georgia'
  }

  &__divCenter {
    text-align: center;
   margin-top: 3rem;
   

}
    

}

@media (min-width: 40rem){
  .profil-page{
    width: 30rem;
    margin: 0 auto;
  }
}

