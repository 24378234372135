.pp{
    width: 90%;
    margin: 1rem auto;
    margin-bottom: 5rem;

    &__heading{
        color: $color-white;
    }
    &__headingSecound{
        color: $color-white;
    }
    &__paragraph{
        color: $color-white;
    }
}