.heading {
    color: $color-white;
}

.icon {
    color: $color-white;
}
.form {
    width: 80%;
    margin: 0 auto;
    text-align: center;
}

.link {

    list-style: none;
    color: $color-white;
    text-decoration: none;
    font-weight: bold;
    display: inline-block;
    font-size: 1.4rem;
    transition: all .2s;
}


.button {
       border: none;
       padding: 1rem 2rem;
       border-radius: 1.6rem;
       background-color: $color-secondary-handy;
       color: $color-black;
       
}


.input {

    font-size: 1.5rem;
    font-family: inherit;
    padding: 0.2rem 1rem;
    border-radius: 3rem;
    background-color: $color-white;
    border: none;
    border-bottom: 3px solid transparent;
    display: block;
    background-color: $color-white;
    width: 90%;
    margin-top: 2rem;

}


.center__smaller {
    width: 80%;
    margin: 0 auto;
    text-align: center;
}

.loading {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    color: white;
}


@media (min-width: 40rem){
    .link:hover{
        padding: 1rem 2rem;
        background-color: black;
        border: solid 1px white;
        border-radius: 14px;
        transform: scale(1.1);
    }
    .button{
        background-color: $color-secondary;
    }

}